import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserData, fetchConversations, getQRCode, getCustomQRCode } from '../config/api';
import QRCodeOverlay from '../overlays/QRCodeOverlay';
import '../styles/dashboard.css';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [qrCode, setQrCode] = useState(null);
  const [enlargedQRCode, setEnlargedQRCode] = useState(null);
  const [enlargedQRCodeContents, setEnlargedQRCodeContents] = useState(null);
  const [customQrCode, setCustomQrCode] = useState(null);
  const [customQrData, setCustomQrData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

//   const [appErrorMsg, setAppErrorMsg] = useState('');

  useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            var userResponse = null;
            try {
                userResponse = await fetchUserData();
                setUserData(userResponse.data);
            } catch (err) {
                console.log(err);
                // setIsLoading(false);
                setError('Failed to fetch user data. Please try again.');
            }

            try {
                const conversationsResponse = await fetchConversations();
                if (conversationsResponse.data.length === 0 
                    || conversationsResponse.data == null) {
                    setConversations([]);
                } else {
                    setConversations(conversationsResponse.data);
                }
            } catch (err) {
                console.log(err);
                // setIsLoading(false);
                setError('Failed to fetch conversations data. Please try again.');
            }

            if (userResponse != null && userResponse.data.id) {
                // const qrResponse = await getQRCode(userResponse.data.id);
                // //Decode the base64 datastring to a buffer
                // const qrCode = Buffer.from(qrResponse.data, 'base64');
                // setQrCode(qrCode)
                try {
                    const qrResponse = await getQRCode(userResponse.data.id);
                    
                    // Create a Blob from the response data
                    const blob = new Blob([qrResponse.data], { type: 'image/png' });
                    
                    // Create a URL for the Blob
                    const url = URL.createObjectURL(blob);
                    
                    setQrCode(url);
                } catch (error) {
                    console.error('Error fetching QR code:', error);
                }
            }

            setIsLoading(false);
        //   } catch (err) {
        //     setError('Failed to fetch data. Please try again.');
        //     setIsLoading(false);
        //   }
        };



        fetchData();
  }, [navigate]);


  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (isLoading) {
    return <div className="app-loading">Loading...</div>;
  }

  const handleCustomQrGenerate = async () => {
    if (!customQrData) {
      setError('Please enter data for the custom QR code');
      return;
    }

    try {
      const qrResponse = await getCustomQRCode(encodeURIComponent(customQrData));
      const blob = new Blob([qrResponse.data], { type: 'image/png' });
      const url = URL.createObjectURL(blob);
      setCustomQrCode(url);
      setError('');
    } catch (error) {
      console.error('Error generating custom QR code:', error);
      setError('Failed to generate custom QR code. Please try again.');
    }
  };

  const handleQRCodeClick = (qrCodeUrl, contents = null) => {
    setEnlargedQRCode(qrCodeUrl);
    setEnlargedQRCodeContents(contents);
  };

  const handleCloseOverlay = () => {
    setEnlargedQRCode(null);
    setEnlargedQRCodeContents(null);
  };

//   if (error) {
//     setAppErrorMsg(error);
//     // document.getElementById('app-error').innerText = error;
//     // return <div className="app-error">{error}</div>;
//   }

  return (
    <div className="app-container">
      <div className="app-error" id="app-error">{error}</div>
      <header className="app-header">
        <h1 className="app-title">Tibbar Nexus</h1>
        <nav className="app-nav">
          <button className="app-nav-item">Home</button>
          <button className="app-nav-item">Profile</button>
          <button className="app-nav-item">Settings</button>
          <button className="app-nav-item app-logout" onClick={handleLogout}>Logout</button>
        </nav>
      </header>

      <main className="app-main">
        <section className="app-welcome">
          <h2 className="app-subtitle">Welcome, {userData?.username}</h2>
          <p className="app-text">Jack into your personal data stream.</p>
        </section>

        <div className="app-grid">
          <section className="app-card app-user-info">
            <h3 className="app-card-title">User Profile</h3>
            <div className="app-user-details">
              <p><strong>Username:</strong> {userData?.username}</p>
              <p><strong>Email:</strong> {userData?.email}</p>
              <p><strong>Member Since:</strong> {new Date(userData?.created_at).toLocaleDateString()}</p>
            </div>
          </section>

          <section className="app-card app-conversations">
            <h3 className="app-card-title">Data Streams</h3>
            {conversations.length === 0 ? (
              <p className="app-text">No active data streams found. Initialize a new connection.</p>
            ) : (
              <ul className="app-list">
                {conversations.map((conversation) => (
                  <li key={conversation.id} className="app-list-item">
                    <h4 className="app-item-title">{conversation.title}</h4>
                    <p className="app-item-subtitle">{conversation.last_message}</p>
                  </li>
                ))}
              </ul>
            )}
          </section>

          <section className="app-card app-timeline">
            <h3 className="app-card-title">Neural Timeline</h3>
            <div className="app-timeline-container">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="app-timeline-item">
                  <div className="app-timeline-point"></div>
                  <div className="app-timeline-content">
                    <h4 className="app-item-title">Milestone {index + 1}</h4>
                    <p className="app-text">Future content will be uploaded here.</p>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="app-card app-qr-code">
            <h3 className="app-card-title">Neural Link QR</h3>
            {qrCode ? (
              <img
                // src={`data:image/png;base64,${qrCode}`}
                src={qrCode}
                alt="QR Code for Neural Link"
                className="app-qr-image clickable-qr"
                onClick={() => handleQRCodeClick(qrCode)}
              />
            ) : (
              <p className="app-text">Neural Link QR not available</p>
            )}
            <p className="app-text">Scan to sync your cyberdeck.</p>
          </section>

          <section className="app-card app-custom-qr-code">
            <h3 className="app-card-title">Generate Custom QR Code</h3>
            <div className="app-custom-qr-input">
              <input
                type="text"
                value={customQrData}
                onChange={(e) => setCustomQrData(e.target.value)}
                placeholder="Enter data for custom QR code"
                className="app-input"
              />
              <button onClick={handleCustomQrGenerate} className="app-button">
                Generate QR
              </button>
            </div>
            {customQrCode && (
              <img
                src={customQrCode}
                alt="Custom QR Code"
                className="app-qr-image clickable-qr"
                onClick={() => handleQRCodeClick(customQrCode, customQrData)}
                />
            )}
          </section>
        </div>
      </main>

      <footer className="app-footer">
        <p className="app-text">&copy; 2024 Tibbar Nexus. All rights reserved in the neon-lit streets of cyberspace.</p>
      </footer>

      {enlargedQRCode && (
        <QRCodeOverlay 
          qrCodeUrl={enlargedQRCode} 
          onClose={handleCloseOverlay} 
          contents={enlargedQRCodeContents}
        />
      )}
    </div>
  );
};

export default Dashboard;


// import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { fetchUserData, fetchConversations, getQRCode } from '../config/api';
// import '../styles/dashboard.css';

// const Dashboard = () => {
//   const [userData, setUserData] = useState(null);
//   const [conversations, setConversations] = useState([]);
//   const [qrCode, setQrCode] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         navigate('/login');
//         return;
//       }

//       try {
//         const userResponse = await fetchUserData();
//         setUserData(userResponse.data);

//         const conversationsResponse = await fetchConversations();
//         setConversations(conversationsResponse.data);

//         if (userResponse.data.id) {
//           const qrResponse = await getQRCode(userResponse.data.id);
//           setQrCode(qrResponse.data);
//         }
//         // const userResponse = await axios.get(`${API_BASE_URL}/api/user`, {
//         //   headers: { Authorization: `Bearer ${token}` }
//         // });
//         // setUserData(userResponse.data);

//         // const conversationsResponse = await axios.get(`${API_BASE_URL}/api/conversations`, {
//         //   headers: { Authorization: `Bearer ${token}` }
//         // });
//         // setConversations(conversationsResponse.data);

//         setIsLoading(false);
//       } catch (err) {
//         setError('Failed to fetch data. Please try again.');
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, [navigate]);

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     navigate('/login');
//   };

//   if (isLoading) {
//     return <div className="text-center mt-8">Loading...</div>;
//   }

//   if (error) {
//     return <div className="text-center mt-8 text-red-500">{error}</div>;
//   }

//   return (
//     <div className="app-container">
//       <div className="dashboard">
//         <div className="dashboard-header">
//           <h1 className="app-title dashboard-title">Welcome, {userData?.username}</h1>
//           <button
//             onClick={handleLogout}
//             className="app-button dashboard-logout"
//           >
//             Logout
//           </button>
//         </div>
        
//         <div className="dashboard-content">
//           <div className="dashboard-card">
//             <h2 className="app-title">Your Conversations</h2>
//             {conversations.length === 0 ? (
//               <p>No conversations found.</p>
//             ) : (
//               <ul>
//                 {conversations.map((conversation) => (
//                   <li key={conversation.id} className="mb-2 p-2 border-b">
//                     <h3 className="font-semibold">{conversation.title}</h3>
//                     <p className="text-gray-600">{conversation.last_message}</p>
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </div>

//           <div className="dashboard-card">
//             <h2 className="app-title">QR Code for Android App</h2>
//             {qrCode ? (
//               <img
//                 src={`data:image/png;base64,${qrCode}`}
//                 alt="QR Code for Android App"
//                 className="mx-auto"
//               />
//             ) : (
//               <p>QR Code not available</p>
//             )}
//             <p className="text-center mt-2">Scan this QR code with your Android app to login</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;