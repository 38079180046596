// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */

/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f2f5;
    color: #333;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  /* Basic container for centering content */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  /* Utility classes */
  .text-center {
    text-align: center;
  }
  
  .mt-4 {
    margin-top: 1rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  /* Basic button styles */
  .btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #4a90e2;
    color: white;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #357ae8;
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB,yBAAyB;AACzB;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA;IACE;;gBAEY;IACZ,mCAAmC;IACnC,kCAAkC;IAClC,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE;eACW;EACb;;EAEA,0CAA0C;EAC1C;IACE,iBAAiB;IACjB,cAAc;IACd,eAAe;EACjB;;EAEA,oBAAoB;EACpB;IACE,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA,wBAAwB;EACxB;IACE,qBAAqB;IACrB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,sCAAsC;IACtC,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/index.css */\n\n/* Reset default styles */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  body {\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n      sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    background-color: #f0f2f5;\n    color: #333;\n  }\n  \n  code {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n      monospace;\n  }\n  \n  /* Basic container for centering content */\n  .container {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 0 15px;\n  }\n  \n  /* Utility classes */\n  .text-center {\n    text-align: center;\n  }\n  \n  .mt-4 {\n    margin-top: 1rem;\n  }\n  \n  .mb-4 {\n    margin-bottom: 1rem;\n  }\n  \n  /* Basic button styles */\n  .btn {\n    display: inline-block;\n    padding: 0.5rem 1rem;\n    font-size: 1rem;\n    text-align: center;\n    text-decoration: none;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n    cursor: pointer;\n  }\n  \n  .btn-primary {\n    background-color: #4a90e2;\n    color: white;\n    border: none;\n  }\n  \n  .btn-primary:hover {\n    background-color: #357ae8;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
