// // src/config/api.js
// export const API_BASE_URL = 'https://tibbar-backend-production.up.railway.app';

// api.js or similar file
import axios from 'axios';

const API_BASE_URL = 'https://tibbar.rttgnck.com';
// const API_BASE_URL = 'https://tibbar-backend.railway.internal';
const UI_BASE_URL = 'https://tibbarhole.rttgnck.com';
const LOCAL_URL = 'http://localhost:3000';

// const api = axios.create({
//   baseURL: API_BASE_URL,
//   headers: {
//     'Content-Type': 'application/json',
//     "Access-Control-Allow-Origin": UI_BASE_URL,
//   },
//   withCredentials: true,
// });

const token = localStorage.getItem('token');

const api = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
    //   "Authorization": `Bearer ${token}`,
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": [UI_BASE_URL, LOCAL_URL],
      "Access-Control-Allow-Credentials": "true",
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,HEAD',
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
    },
  });

// // Add a request interceptor to include the token in the header
// api.interceptors.request.use(
//     (config) => {
//       const token = localStorage.getItem('token');
//       if (token) {
//         config.headers['Authorization'] = `Bearer ${token}`;
//       }
//       return config;
//     },
//     (error) => Promise.reject(error)
// );


// Auth Routes
export const register = (userData) => api.post('/api/auth/register', userData);
export const login = (credentials) => api.post('/api/auth/login', credentials);
export const getQRCode = async (userId) => {
    try {
      const response = await api.get(`/api/auth/qr-code?userId=${userId}`, {
        responseType: 'arraybuffer'
      });
      return response;
    } catch (error) {
      console.error('Error fetching QR code:', error);
      throw error;
    }
  };
  

// API Routes
export const fetchUserData = () => api.get('/api/v1/user');
export const fetchConversations = () => api.get('/api/v1/conversations');
export const newConversation = () => api.post('/api/v1/conversation');
export const storeData = (data) => api.post('/api/v1/store', data);
export const fetchData = () => api.get('/api/v1/fetch');
// export const getQRCode = (userId) => api.get(`/api/auth/qr-code?userId=${userId}`);

export const getCustomQRCode = async (dataString) => {
    try {
      const response = await api.get(`/api/v1/custom-qr-code?dataString=${dataString}`, {
        responseType: 'arraybuffer'
      });
      return response;
    } catch (error) {
      console.error('Error fetching Custom QR code:', error);
      throw error;
    }
  };

export default api;