import React, { useState } from 'react';
// import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../config/api';
import '../styles/register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { username, email, password, confirmPassword } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setError('');
    setIsLoading(true);

    try {
    //   const response = await axios.post(`${api.API_BASE_URL}/api/auth/register`, {
    //     username,
    //     email,
    //     password,
    //   });

      const response = await register({
            username,
            email,
            password
      });

      
      localStorage.setItem('token', response.data.token);
      setIsLoading(false);
      navigate('/dashboard');
    } catch (err) {
      setError(err.response.data.message || 'An error occurred during registration');
      setIsLoading(false);
    }
  };

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={onSubmit}>
        <h2 className="app-title register-title">Create your account</h2>
        <input
          type="text"
          name="username"
          value={username}
          onChange={onChange}
          placeholder="Username"
          required
          className="app-input register-input"
        />
        <input
          type="email"
          name="email"
          value={email}
          onChange={onChange}
          placeholder="Email address"
          required
          className="app-input register-input"
        />
        <input
          type="password"
          name="password"
          value={password}
          onChange={onChange}
          placeholder="Password"
          required
          className="app-input register-input"
        />
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={onChange}
          placeholder="Confirm Password"
          required
          className="app-input register-input"
        />
        {error && <div className="error-message">{error}</div>}
        <button
          type="submit"
          className="app-button register-button"
          disabled={isLoading}
        >
          {isLoading ? 'Registering...' : 'Register'}
        </button>
        <Link to="/login" className="register-link">Already have an account? Sign in</Link>
      </form>
    </div>
  );
};

export default Register;