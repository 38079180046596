import React from 'react';

const QRCodeOverlay = ({ qrCodeUrl, onClose, contents }) => {
  return (
    <div className="qr-code-overlay" onClick={onClose}>
      <div className="qr-code-overlay-content" onClick={(e) => e.stopPropagation()}>
        {contents && (
          <div className="qr-code-contents">
            <h3>QR Code Contents:</h3>
            <p>{contents}</p>
          </div>
        )}
        <img src={qrCodeUrl} alt="Enlarged QR Code" className="qr-code-overlay-image" />
      </div>
    </div>
  );
};

export default QRCodeOverlay;

// import React from 'react';

// const QRCodeOverlay = ({ qrCodeUrl, onClose }) => {
//   return (
//     <div className="qr-code-overlay" onClick={onClose}>
//       <div className="qr-code-overlay-content" onClick={(e) => e.stopPropagation()}>
//         <img src={qrCodeUrl} alt="Enlarged QR Code" className="qr-code-overlay-image" />
//       </div>
//     </div>
//   );
// };

// export default QRCodeOverlay;