import React, { useState } from 'react';
// import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { login } from '../config/api';
import '../styles/login.css';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
    //   const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
    //     email,
    //     password,
    //   });
      
      const response = await login({
        email,
        password
      });

      localStorage.setItem('token', response.data.token);
      setIsLoading(false);
      navigate('/dashboard');
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred during login');
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={onSubmit}>
        <h2 className="app-title login-title">Sign in to your account</h2>
        <input
          type="email"
          name="email"
          value={email}
          onChange={onChange}
          placeholder="Email address"
          required
          className="app-input login-input"
        />
        <input
          type="password"
          name="password"
          value={password}
          onChange={onChange}
          placeholder="Password"
          required
          className="app-input login-input"
        />
        {error && <div className="error-message">{error}</div>}
        <button
          type="submit"
          className="app-button login-button"
          disabled={isLoading}
        >
          {isLoading ? 'Signing in...' : 'Sign in'}
        </button>
        <Link to="/register" className="login-link">Need an account? Register</Link>
      </form>
    </div>
  );
};

export default Login;