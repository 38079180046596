// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(45deg, var(--dark-bg) 0%, var(--light-bg) 100%);
  }
  
  .login-form {
    background-color: rgba(26, 26, 26, 0.8);
    border: 2px solid var(--primary-orange);
    border-radius: 10px;
    padding: 40px;
    width: 300px;
    box-shadow: 0 0 20px var(--primary-orange);
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .login-input {
    margin-bottom: 20px;
  }
  
  .login-button {
    width: 100%;
    margin-top: 20px;
  }
  
  .login-link {
    display: block;
    text-align: center;
    margin-top: 20px;
    color: var(--secondary-orange);
    text-decoration: none;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,2EAA2E;EAC7E;;EAEA;IACE,uCAAuC;IACvC,uCAAuC;IACvC,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,0CAA0C;EAC5C;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,8BAA8B;IAC9B,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background: linear-gradient(45deg, var(--dark-bg) 0%, var(--light-bg) 100%);\n  }\n  \n  .login-form {\n    background-color: rgba(26, 26, 26, 0.8);\n    border: 2px solid var(--primary-orange);\n    border-radius: 10px;\n    padding: 40px;\n    width: 300px;\n    box-shadow: 0 0 20px var(--primary-orange);\n  }\n  \n  .login-title {\n    text-align: center;\n    margin-bottom: 30px;\n  }\n  \n  .login-input {\n    margin-bottom: 20px;\n  }\n  \n  .login-button {\n    width: 100%;\n    margin-top: 20px;\n  }\n  \n  .login-link {\n    display: block;\n    text-align: center;\n    margin-top: 20px;\n    color: var(--secondary-orange);\n    text-decoration: none;\n  }\n  \n  .login-link:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
